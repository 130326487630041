<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <SideMenu :instanceInfo="instanceInfo" />
          <div v-if="myProfile" class="col-md-9">


            <h2 class="pink">{{ myProfile.username }}</h2>

            <p></p>
            <div v-if="errorMessage" class="alert alert-danger alert-dismissible">
              <button @click="errorMessage = null" type="button" class="close" data-dismiss="alert"
                aria-label="Close"><span aria-hidden="true">×</span></button>
              <p>{{ errorMessage }}</p>
            </div>
            <div id="accordion" role="tablist" aria-multiselectable="true">

              <form @submit.prevent="submitUpdate">

                <input class="input" name="email" type="hidden" value="demoaccount@gmail.com">



                <div class="card">
                  <div class="card-header" role="tab" id="headingTwo">
                    <div class="toggle">
                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        General information:
                      </a>
                    </div>
                  </div>

                  <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div class="card-block">
                      <div class="box">
                        <ul class="general-information-fields">
                          <li>
                            <label>Username:</label>
                            <input v-model="myProfile.username" placeholder="Username"
                              class="form-control ui-autocomplete-input" type="text" autocomplete="off">

                          </li>
                          <li>
                            <label>Gender:</label>
                            <select v-model="myProfile.gender" class="form-control" style="max-width:150px;"
                              name="gender">
                              <option :value="null" selected="selected">Please select...</option>
                              <option value="MALE">Man</option>
                              <option value="FEMALE">Woman</option>
                            </select>
                          </li>
                          <li>
                            <label>Country:</label>
                            <input v-model="myProfile.country" placeholder="Enter country"
                              class="form-control ui-autocomplete-input" id="geoAutocomplete" name="locationAutocomplete"
                              type="text" autocomplete="off">

                          </li>
                          <li>
                            <label>City:</label>
                            <input v-model="myProfile.city" placeholder="Enter city"
                              class="form-control ui-autocomplete-input" id="geoAutocomplete" name="locationAutocomplete"
                              type="text" autocomplete="off">

                          </li>
                          <li>
                            <label>Birthdate:</label>
                            <select v-model="birthDay" class="form-control input-sm" name="birth_day"
                              style="float:left; max-width:60px; margin-right:3px;">
                              <option value="01">1st</option>
                              <option value="02">2nd</option>
                              <option value="03">3rd</option>
                              <option value="04">4th</option>
                              <option value="05">5th</option>
                              <option value="06">6th</option>
                              <option value="07">7th</option>
                              <option value="08">8th</option>
                              <option value="09">9th</option>
                              <option value="10">10th</option>
                              <option value="11">11th</option>
                              <option value="12">12th</option>
                              <option value="13">13th</option>
                              <option value="14">14th</option>
                              <option value="15">15th</option>
                              <option value="16">16th</option>
                              <option value="17">17th</option>
                              <option value="18">18th</option>
                              <option value="19">19th</option>
                              <option value="20">20th</option>
                              <option value="21">21st</option>
                              <option value="22">22nd</option>
                              <option value="23">23rd</option>
                              <option value="24">24th</option>
                              <option value="25">25th</option>
                              <option value="26">26th</option>
                              <option value="27">27th</option>
                              <option value="28">28th</option>
                              <option value="29">29th</option>
                              <option value="30">30th</option>
                              <option value="31">31st</option>
                            </select>

                            <select v-model="birthMonth" class="form-control input-sm" name="birth_month"
                              style="float:left; max-width:65px; margin-right:3px;">
                              <option value="01">Jan</option>
                              <option value="02">Feb</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">Aug</option>
                              <option value="09">Sept</option>
                              <option value="10">Oct</option>
                              <option value="11">Nov</option>
                              <option value="12">Dec</option>
                            </select>

                            <select v-model="birthYear" class="form-control input-sm" name="birth_year"
                              style="float:left; max-width:80px;">
                              <option v-for="n in 100" :value="(new Date().getFullYear() - n - 17).toString()"
                                :key="new Date().getFullYear() - n - 17">{{ new Date().getFullYear() - n - 17 }}
                              </option>
                            </select>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <input type="submit" class="btn" value="Save">
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" role="tab" id="headingThree">
                    <div class="toggle">
                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        Personal information:
                      </a>
                    </div>
                  </div>

                  <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div class="card-block">
                      <div class="box">

                        <ul class="personal-information-fields">

                          <li>
                            <label>Height:</label>
                            <input v-model="myProfile.height" placeholder="Height" class="form-control"
                              style="max-width:200px;" type="text">

                          </li>
                          <li>
                            <label for="body_type">Body type</label>
                            <select v-model="myProfile.bodyType" class="form-control" style="max-width:200px;"
                              id="body_type" name="body_type">
                              <option value="null">Please select...</option>
                              <option value="Athletic / toned">Athletic / toned</option>
                              <option value="Average">Average</option>
                              <option value="BBW/BHM">BBW/BHM</option>
                              <option value="Curvy">Curvy</option>
                              <option value="Slender">Slender</option>
                              <option value="Voluptuous">Voluptuous</option>
                              <option value="Other">Other</option>
                            </select>

                          </li>
                          <li>
                            <label for="hair_color">Hair Colour</label>
                            <select v-model="myProfile.hairColour" class="form-control" style="max-width:200px;"
                              id="hair_color" name="hair_color">
                              <option value="null">Please select...</option>
                              <option value="Black">Black</option>
                              <option value="Blonde">Blonde</option>
                              <option value="Brown">Brown</option>
                              <option value="Red">Red</option>
                              <option value="Gray">Gray</option>
                              <option value="White">White</option>
                              <option value="Other">Other</option>
                            </select>

                          </li>
                          <li>
                            <label for="eye_color">Eye Colour</label>
                            <select v-model="myProfile.eyeColour" class="form-control" style="max-width:200px;"
                              id="eye_color" name="eye_color">
                              <option value="null">Please select...</option>
                              <option value="Black">Black</option>
                              <option value="Brown">Brown</option>
                              <option value="Blue">Blue</option>
                              <option value="Green">Green</option>
                              <option value="Gray">Gray</option>
                              <option value="Hazel">Hazel</option>
                              <option value="Other">Other</option>
                            </select>

                          </li>
                          <li>
                            <label for="smoking">Smoker</label>
                            <select v-model="myProfile.smoker" class="form-control" style="max-width:200px;" id="smoking"
                              name="smoking">
                              <option :value="null">Please select...</option>
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>

                          </li>
                          <li>
                            <label for="marital_status">Marital status</label>
                            <select v-model="myProfile.maritalStatus" class="form-control" style="max-width:200px;"
                              id="marital_status" name="marital_status">
                              <option value="null">Please select...</option>
                              <option value="Single">Single</option>
                              <option value="Relationship">Relationship</option>
                              <option value="Married">Married</option>
                              <option value="Divorced">Divorced</option>
                              <option value="Widowed">Widowed</option>
                            </select>

                          </li>
                        </ul>
                      </div>
                      <p>
                        <input type="submit" class="btn" value="Save">
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" role="tab" id="headingFour">
                    <div class="toggle">
                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                        aria-expanded="false" aria-controls="collapseFour">
                        Looking to chat with:
                      </a>
                    </div>
                  </div>

                  <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour">
                    <div class="card-block">
                      <div class="box">
                        <ul class="personal-information-fields">
                          <li>
                            <label for="looking_for_age_min">Min. age</label>
                            <div class="select-container">
                              <select v-model="myProfile.lookingForMinAge" name="looking_for_age_min"
                                id="looking_for_age_min">
                                <option :value="null">Please select...</option>
                                <option v-for="n in 82" :value="(n + 17).toString()" :key="n">
                                  {{ n + 17 }}
                                </option>
                              </select>
                            </div>
                          </li>
                          <li>
                            <label for="looking_for_age_max">Max. age</label>
                            <div class="select-container">
                              <select v-model="myProfile.lookingForMaxAge" name="looking_for_age_max"
                                id="looking_for_age_max">
                                <option :value="null">Please select...</option>
                                <option v-for="n in 82" :value="(n + 17).toString()" :key="n">
                                  {{ n + 17 }}
                                </option>
                              </select>
                            </div>
                          </li>
                          <li>
                            <label>Gender:</label>
                            <div class="select-container">
                              <select v-model="myProfile.lookingForGender" name="seek">
                                <option :value="null">Please select...</option>
                                <option value="MALE">Men</option>
                                <option value="FEMALE">Women</option>
                              </select>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <input type="submit" class="btn" value="Save">
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" role="tab" id="headingSix">
                    <div class="toggle">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false"
                        aria-controls="collapseSix">
                        My photos:
                      </a>
                    </div>
                  </div>

                  <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix">
                    <div class="card-block">
                      <div class="box">
                        <div class="row">
                          <div class="col-md-4">
                            <h3 class="title">Profile photo</h3>
                            <div class="row">
                              <img v-if="currentImage == null" src="/global/img/thumb-l.gif" class="rounded2">
                              <img v-else :src="'/uploads/users/' + currentImage.name" class="rounded2">
                            </div>
                            <div class="row" style="margin-top: 5px;">
                              <div class="col-md-12" style="padding:0px;">
                                <div class="file-up2">
                                  <input id="profileImage" class="file" name="image" type="file"
                                    accept=".gif,.jpg,.jpeg,.png" @change="fileSelect">

                                  <a class="btn-addphoto2" href="javascript:void(0)"><i class="camera"></i> Change</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <template v-if="fileSelected"><br />Click "Save" to upload <strong>{{ fileSelected }}</strong> as
                          your profile picture</template>
                      </div>
                      <p><input type="submit" class="btn" value="Save"></p>
                    </div>
                  </div>
                </div>
              </form>
            </div><!--/accordion-->
          </div>
        </div>
      </div>
    </div>
    <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
  </div>
</template>

<script>
import { ref } from 'vue';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import SideMenu from '@/app/components/SideMenu';
import { fetchMyProfile, updateMyProfile } from '@/app/api/member';
import moment from 'moment';
import { useUser } from '@/app/use/user';


export default {
  name: 'MyProfile',
  components: { Header, Footer, SideMenu },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {

    const myProfile = ref(null);
    const currentImage = ref(null);

    const birthDay = ref(null);
    const birthMonth = ref(null);
    const birthYear = ref(null);

    const errorMessage = ref('');

    const { updateMemberName } = useUser();

    const loadMyProfile = () => {
      fetchMyProfile()
        .then(async data => {
          myProfile.value = data;
          const birthDate = new Date(Date.parse(data.birthDate));
          birthDay.value = moment(birthDate).format("DD");
          birthMonth.value = moment(birthDate).format("MM");
          birthYear.value = moment(birthDate).format("YYYY");
          currentImage.value = data.image;
          updateMemberName(data.username);
        });
    };

    loadMyProfile();

    const submitUpdate = () => {
      if (myProfile.value.bodyType === 'null') {
        myProfile.value.bodyType = null;
      }
      if (myProfile.value.hairColour === 'null') {
        myProfile.value.hairColour = null;
      }
      if (myProfile.value.eyeColour === 'null') {
        myProfile.value.eyeColour = null;
      }
      if (myProfile.value.maritalStatus === 'null') {
        myProfile.value.maritalStatus = null;
      }
      const birthDateString = birthYear.value + '-' + birthMonth.value + '-' + birthDay.value;
      if (!moment(birthDateString, "YYYY-MM-DD", true).isValid() || moment().diff(birthDateString, 'years', false) < 18) {
        errorMessage.value = "Invalid birthdate";
        return;
      }
      if (myProfile.value.lookingForMinAge > myProfile.value.lookingForMaxAge) {
        errorMessage.value = "Invalid age range";
        return;
      }
      if (myProfile.value.username.length < 5) {
        errorMessage.value = "Username too short, must be at least 5 characters";
        return;
      }
      myProfile.value.birthDate = birthDateString;

      const imageInput = document.querySelector('#profileImage');
      const file = imageInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result;
          myProfile.value.image = {
            name: file.name,
            content: base64File
          };
          submitProfileData();
        };
        imageInput.value = null;
        fileSelected.value = null;
      } else {
        myProfile.value.image = null;
        submitProfileData();
      }
    };

    const submitProfileData = () => {
      updateMyProfile(myProfile.value)
        .then(() => {
          loadMyProfile();
          errorMessage.value = null;
        })
        .catch(() => {
          errorMessage.value = "Update of your profile data failed. Try again later or contact our support!";
        });
    };

    const fileSelected = ref('');

    const fileSelect = () => {
      const imageInput = document.querySelector('#profileImage');
      const file = imageInput.files[0];
      if (file) {
        fileSelected.value = file.name;
      }
    }

    return {
      loadMyProfile,
      myProfile,
      currentImage,
      birthDay,
      birthMonth,
      birthYear,
      submitUpdate,
      errorMessage,
      fileSelected,
      fileSelect
    };
  },
};
</script>
